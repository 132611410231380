<template>
  <div class="create-question rounded-xl relative border border-gray-103 w-[500px]" :id="questions.index"
    :class="openCheck ? 'mb-[4.25rem]' : 'mb-[1.25rem]'"
    :style="`border:1px solid ${color}; border-top:60px solid ${color}`">
    <div class="header-index-question flex">
      <div class="cursor-pointer mr-2" @click="openQuestion">
        <img width="25"
          :src="openCheck ? require('@/assets/images/icon/icons8-up-squared-50.png') : require('@/assets/images/icon/icons8-drop-down-50.png')">
        <!--        {{openCheck?'Thu gọn':'Xem thêm'}}-->
      </div>
      <div class=" ml-1">
        {{ index_question + 1 }}.
      </div>
    </div>
    <div class="header-tittle-question" v-html="replace_image_header.content">
    </div>
    <div class="flex absolute top-[-40px] right-[30px]">
      <div class="cursor-pointer mr-2">
        <img width="30" :src="require('@/assets/images/icon/copy.svg')" class="object-contain w-[30px] h-[30px]"
          @click="copyQuestion(questions)">
      </div>
      <button @click="clearQuestion(questions.random_id ? questions.random_id : questions.id, index_question)"
        class="w-[30px]  "><img :src="require('@/assets/images/icon/trash.png')" />
      </button>
    </div>
    <div class="block-btn mb-8 absolute bottom-[-73px] left-[-1px] ml-3">
      <div v-if="openCheck" class="h-[40px] rounded-b-lg flex items-center px-2 text-white cursor-pointer"
        :style="`border:1px solid ${color}; background:${color}`" @click="addQuestion(questions)">
        Thêm câu hỏi
      </div>
      <!--      <BtnBase-->
      <!--          @click="openBlockAnswer(questions)"-->
      <!--          :icon="require('@/assets/images/icon/add_service.svg')"-->
      <!--          text="Thêm đáp án"-->
      <!--          :color="'#ffffff'"-->
      <!--          :bg_color="color"-->
      <!--          :width="200"-->

      <!--      />-->
    </div>
    <div v-if="openCheck">
      <div class="my-3  underline  font-bold ">
        Câu hỏi
      </div>
      <!--    <ck-content class="ck-news" :value="questions.content" @input="(val) => questions.content = val"  />-->
      <Tinymce class="ck-news" v-model="questions.content"></Tinymce>
      <div>
        <input type="text" v-model="questions.slug" placeholder="Nhập slug"
          class="border border-gray-103 mt-4 px-3 py-2 w-[100%]">
      </div>
      <div class="block-action">
        <div class="block-upload w-[100%]">
          <div class="upload-file justify-center w-[50%]cursor-pointer" @click="chooseFileTemporary(questions.random_id)">
            <img src="@/assets/images/icon/upload_file.svg" class="mr-2">
            <span class="drop-file">DROP YOUR FILE HERE</span> Or <span class="drop-file"> load</span> from computer
          </div>
          <input type="file" :id="`file${questions.random_id}`" hidden
            @change="getFileTemporary($event, questions.random_id)" accept=".mp3,audio/*">
          <div class="add__audio">
            <audio class="audio w-[full]" :src="questions.media && questions.media.url ? questions.media.url + `?time=1597104915` : ''" controls>
            </audio>
          </div>
        </div>
        <div class="block-page-break">
          <input v-model="questions.page_break" type="checkbox" id="vehicle1" :name="questions.random_id">
          <label for="vehicle1"> Ngắt trang</label>
        </div>
      </div>
      <div class="add_answer flex w-[100%]">
        <div class="select_answer w-[50%]">
          <select v-model="questions.type_answer" v-if="questions.questions.length <= 0" @change="questions.answers = []"
            class="h-[40px] w-[100%] rounded-full px-2 bg-white" :style="`border:2px solid ${color}; color:${color}`">
            <option :value="'null'">Chọn loại câu hỏi</option>
            <option :value="1">Câu 1 lựa chọn</option>
            <option :value="2">Câu nhiều lựa chọn</option>
            <option :value="3">Câu tự luận</option>
            <option :value="4">Câu chọn cặp</option>
          </select>
        </div>
        <div class="ml-2 w-[50%]" v-if="questions.type_answer == 1 || questions.type_answer == 2">
          <BtnBase @click="openBlockAnswer(questions)" :icon="require('@/assets/images/icon/add_service.svg')"
            text="Thêm đáp án" :color="color" :bg_color="'#ffffff'" :width="130" />
        </div>
      </div>
      <div class="underline  font-bold mb-4 " v-if="questions.type_answer != 3">
        Đáp án
      </div>

      <!--    table-->
      <table class="table w-full table-fixed " v-if="questions.type_answer == 1 || questions.type_answer == 2">

        <tbody class="flex flex-col ">
          <tr class=" bg-[#4097A3] text-white border border-gray-102 order-first inherit">
            <th v-for="(head, index) in Header" :key="index"
              :class="['text-start  px-2 h-[40px] pt-2', 'w-[' + head.width + ']']">
              {{ head.text }}
            </th>
          </tr>
          <tr :class="['border border-gray-102 p-5']" v-if="open_block_answer" :style="`order: ${answerOrder}`">
            <td colspan="4" class="contents">
              <div class=" flex justify-end">
                <button @click="open_block_answer = false" class="w-[40px] border rounded-full mb-2">X</button>
              </div>
              <!--              <ck-content class="ck-news" :value="answer.content" @input="(val) => answer.content = val"  />-->
              <Tinymce class="ck-news" v-model="answer.content"></Tinymce>
              <div class="block-action bord">
                <div class="block-upload">
                  <div class="upload-file cursor-pointer"
                    @click="chooseFileAnswerTemporary((answer.random_id ?? answer.id))">
                    <img src="@/assets/images/icon/upload_file.svg" class="mr-2">
                    <span class="drop-file">DROP YOUR FILE HERE</span> Or <span class="drop-file"> load</span> from
                    computer
                    <input type="file" :id="`file${answer.random_id ?? answer.id}`" hidden
                      @change="getFileAnswerTemporary($event, (answer.random_id ?? answer.id))" accept=".mp3,audio/*">
                  </div>
                  <div class="upload-audio">
                    <audio :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''" controls>
                    </audio>
                  </div>
                </div>
              </div>
              <div class="block-btn w-full justify-center mt-5">
                <BtnBase @click="cancleEdit" :icon="require('@/assets/images/icon/add_service.svg')" text="Huỷ"
                  :color="'#7E7E7E'" :bg_color="'#ffffff'" :width="100" class="mr-8" />
                <BtnBase @click="updateAnswer(questions.answers, 'normal')"
                  :icon="require('@/assets/images/icon/add_service.svg')" text="Lưu" :color="'#3E84A8'"
                  :bg_color="'#ffffff'" :width="100" class="mr-8" />
              </div>
            </td>
          </tr>
          <tr v-for="(answer, index) in questions.answers" :key="index"
            :class="['inherit border border-gray-102 relative pt-4', index % 2 == 1 ? 'bg-[#f8fafc]' : '']"
            :style="`order: ${index + 1}`">
            <td class="table-cell h-[40px] px-2 w-[25%]">
              <div class="flex">
                <input v-if="questions.type_answer == 1" v-bind:checked="answer.is_correct == true" type="radio"
                  @change="radio($event, answer, questions.answers)"
                  :name="`radio-${questions.random_id ? questions.random_id : questions.id}`">
                <input v-if="questions.type_answer == 2" v-model="answer.is_correct" type="checkbox"
                  :name="answer.random_id ?? questions.id">
              </div>
            </td>
            <td class="table-cell px-2 w-[25%]">
              <div v-html="answer.content"></div>
            </td>
            <td class="table-cell relative px-2 w-[35%]">
              <div class="flex relative -top-[5px] h-[30px] justify-center">
                <audio class="h-[30px]" v-if="answer.media && answer.media.url"
                  :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''" controls>
                </audio>
                <div v-else class="h-[30px]"></div>
              </div>
            </td>
            <td class="table-cell px-2 w-[15%]">
              <div class="flex ">
                <BtnInTable @click="editAnswer(questions.answers, answer, index + 1)"
                  :icon="require('@/assets/images/icon/pen-icon.svg')" class="mr-1" :height="20" :width="20" />
                <BtnInTable @click="clearAnswer(questions.answers, answer)"
                  :icon="require('@/assets/images/icon/trash-icon.svg')" :height="20" :width="20" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="questions.type_answer == 4" class="w-full grid grid-cols-2 mt-3 gap-4">
        <div class="col-span-1">
          <div class="flex items-center">
            <div class="text-blue-101 font-medium text-[18px]">VẾ A</div>
            <div class="w-[40px] h-[40px] bg-[#FA7026] rounded flex items-center justify-center cursor-pointer ml-3"
              @click="openBlockAnswerMatch(questions)">
              <img :src="require('@/assets/images/icon/add_service.svg')" alt="">
            </div>
          </div>
          <table class="table w-full table-fixed mt-3">
            <tbody class="flex flex-col ">
              <tr class=" bg-[#787878] text-white border border-gray-102 order-first inherit">
                <th v-for="(head, index) in head_matching" :key="index"
                  :class="['text-center  px-2 h-[40px] pt-2', 'w-[' + head.width + ']']">
                  {{ head.text }}
                </th>
              </tr>
              <tr :class="['border border-gray-102 p-5']" v-if="open_block_answer_left"
                :style="`order: ${answerOrder_left}`">
                <td colspan="4" class="contents">
                  <div class=" flex justify-end">
                    <button @click="open_block_answer_left = false" class="w-[40px] border rounded-full mb-2">X</button>
                  </div>
                  <!--              <ck-content class="ck-news" :value="answer.content" @input="(val) => answer.content = val"  />-->
                  <Tinymce v-model="answer.content" class="ck-news"></Tinymce>
                  <div class="block-action bord">
                    <div class="block-upload">
                      <div class="upload-file cursor-pointer"
                        @click="chooseFileAnswerTemporary((answer.random_id ?? answer.id))">
                        <img src="@/assets/images/icon/upload_file.svg" class="mr-2">
                        <span class="drop-file">DROP YOUR FILE HERE</span>
                        <input type="file" :id="`file${answer.random_id ?? answer.id}`" hidden
                          @change="getFileAnswerTemporary($event, (answer.random_id ?? answer.id))" accept=".mp3,audio/*">
                      </div>
                      <div class="upload-audio">
                        <audio :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''"
                          controls>
                        </audio>
                      </div>
                    </div>
                  </div>
                  <div class="block-btn w-full justify-center mt-5">
                    <BtnBase @click="cancleEdit" :icon="require('@/assets/images/icon/add_service.svg')" text="Huỷ"
                      :color="'#7E7E7E'" :bg_color="'#ffffff'" :width="100" class="mr-8" />
                    <BtnBase @click="updateAnswer(questions.answers, 'left')"
                      :icon="require('@/assets/images/icon/add_service.svg')" text="Lưu" :color="'#3E84A8'"
                      :bg_color="'#ffffff'" :width="100" class="mr-8" />
                  </div>
                </td>
              </tr>
              <tr v-for="(answer, index) in group_a" :key="index"
                :class="['inherit border border-gray-102 relative pt-4', index % 2 == 1 ? 'bg-[#f8fafc]' : '']"
                :style="`order: ${index + 1}`">
                <td class="table-cell h-[40px] px-2 w-[10%]">
                  <div class="flex justify-center">
                    <span class="text-[18px] text-[#616161] font-bold">
                      {{ index + 1 }}
                    </span>
                  </div>
                </td>
                <td class="table-cell px-2 w-[35%]">
                  <div v-html="answer.content"></div>
                </td>
                <td class="table-cell relative px-2 w-[35%]">
                  <div class="flex relative -top-[5px] h-[30px] justify-center">
                    <audio class="h-[30px]" v-if="answer.media && answer.media.url"
                      :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''" controls>
                    </audio>
                    <div v-else class="h-[30px]"></div>
                  </div>
                </td>
                <td class="table-cell px-2 w-[15%]">
                  <div class="flex ">
                    <BtnInTable @click="editAnswerLeft(questions.answers, answer, index + 1)"
                      :icon="require('@/assets/images/icon/pen-icon.svg')" class="mr-1" :height="20" :width="20" />
                    <BtnInTable @click="clearAnswer(questions.answers, answer)"
                      :icon="require('@/assets/images/icon/trash-icon.svg')" :height="20" :width="20" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-span-1">
          <div class="flex items-center">
            <div class="text-blue-101 font-medium text-[18px]">VẾ B</div>
            <div class="w-[40px] h-[40px] bg-[#FA7026] rounded flex items-center justify-center cursor-pointer ml-3"
              @click="openBlockAnswerMatchRight(questions)">
              <img :src="require('@/assets/images/icon/add_service.svg')" alt="">
            </div>
          </div>

          <table class="table w-full table-fixed mt-3">
            <tbody class="flex flex-col ">
              <tr class=" bg-[#787878] text-white border border-gray-102 order-first inherit">
                <th v-for="(head, index) in head_matching" :key="index"
                  :class="['text-center  px-2 h-[40px] pt-2', 'w-[' + head.width + ']']">
                  {{ head.text }}
                </th>
              </tr>
              <tr :class="['border border-gray-102 p-5']" v-if="open_block_answer_right"
                :style="`order: ${answerOrder_right}`">
                <td colspan="4" class="contents">
                  <div class=" flex justify-end">
                    <button @click="open_block_answer_right = false" class="w-[40px] border rounded-full mb-2">X</button>
                  </div>
                  <!--              <ck-content class="ck-news" :value="answer.content" @input="(val) => answer.content = val"  />-->
                  <Tinymce v-model="answer.content" class="ck-news"></Tinymce>
                  <div class="block-action bord">
                    <div class="block-upload">
                      <div class="upload-file cursor-pointer"
                        @click="chooseFileAnswerTemporary((answer.random_id ?? answer.id))">
                        <img src="@/assets/images/icon/upload_file.svg" class="mr-2">
                        <span class="drop-file">DROP YOUR FILE HERE</span>
                        <input type="file" :id="`file${answer.random_id ?? answer.id}`" hidden
                          @change="getFileAnswerTemporary($event, (answer.random_id ?? answer.id))" accept=".mp3,audio/*">
                      </div>
                      <div class="upload-audio">
                        <audio :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''"
                          controls>
                        </audio>
                      </div>
                    </div>
                  </div>
                  <div class="block-btn w-full justify-center mt-5">
                    <BtnBase @click="cancleEdit" :icon="require('@/assets/images/icon/add_service.svg')" text="Huỷ"
                      :color="'#7E7E7E'" :bg_color="'#ffffff'" :width="100" class="mr-8" />
                    <BtnBase @click="updateAnswer(questions.answers, 'right')"
                      :icon="require('@/assets/images/icon/add_service.svg')" text="Lưu" :color="'#3E84A8'"
                      :bg_color="'#ffffff'" :width="100" class="mr-8" />
                  </div>
                </td>
              </tr>
              <tr v-for="(answer, index) in group_b" :key="index"
                :class="['inherit border border-gray-102 relative pt-4', index % 2 == 1 ? 'bg-[#f8fafc]' : '']"
                :style="`order: ${index + 1}`">
                <td class="table-cell h-[40px] px-2 w-[10%]">
                  <div class="flex justify-center">
                    <span class="text-[18px] text-[#616161] font-bold">
                      <input class="w-[40px] border rounded text-center" type="number" :value="answer.stt"
                        @change="addSttAnswer($event, answer, questions.answers)">
                    </span>
                  </div>
                </td>
                <td class="table-cell px-2 w-[35%]">
                  <div v-html="answer.content"></div>
                </td>
                <td class="table-cell relative px-2 w-[35%]">
                  <div class="flex relative -top-[5px] h-[30px] justify-center">
                    <audio class="h-[30px]" v-if="answer.media && answer.media.url"
                      :src="answer.media && answer.media.url ? answer.media.url + `?time=1597104915` : ''" controls>
                    </audio>
                    <div v-else class="h-[30px]"></div>
                  </div>
                </td>
                <td class="table-cell px-2 w-[15%]">
                  <div class="flex ">
                    <BtnInTable @click="editAnswerRight(questions.answers, answer, index + 1)"
                      :icon="require('@/assets/images/icon/pen-icon.svg')" class="mr-1" :height="20" :width="20" />
                    <BtnInTable @click="clearAnswer(questions.answers, answer)"
                      :icon="require('@/assets/images/icon/trash-icon.svg')" :height="20" :width="20" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-full  mt-3 " v-if="questions.questions.length <= 0">
        <span class="underline  font-bold">Giải thích</span>
        <!--      <ck-content class="ck-news" :value="questions.explanation" @input="(val) => questions.explanation = val"  />-->
        <Tinymce v-model="questions.explanation" class="ck-news"></Tinymce>
      </div>
      <hr class="mt-8 border-[2px] border-solid border-[#F1F3F4]" />
      <div class="flex items-center mt-4">
        <h2>SEO</h2>
        <div class="ml-2  cursor-pointer mr-2" @click="openBlockSeo">
          <img width="30"
            :src="blockSeo ? require('@/assets/images/turn-up.png') : require('@/assets/images/turn-down.png')">
          <!--        {{openCheck?'Thu gọn':'Xem thêm'}}-->
        </div>
      </div>
      <div class="seo mt-2" v-if="blockSeo">
        <div>
          <h3>SEO title</h3>
          <input type="text" v-model="questions.seo.title" placeholder="Nhập SEO title"
            class="border border-gray-103 mb-4 px-3 py-2 w-[100%]">
        </div>
        <div>
          <h3>SEO keyword</h3>
          <input type="text" v-model="questions.seo.keyword" placeholder="Nhập SEO keyword"
            class="border border-gray-103 mb-4 px-3 py-2 w-[100%]">
        </div>
        <div>
          <h3>SEO description</h3>
          <textarea v-model="questions.seo.description" name="" id="" cols="30" rows="5"
            placeholder="Nhập SEO description" class="border border-gray-103 mb-4 px-3 py-2 w-[100%]"></textarea>
        </div>
      </div>
      <div class="mt-10">
        <list-question @clearQuestion="lastQuestion" v-if="questions.questions" :list_question="questions.questions"
          :index-tree="current_index"></list-question>
      </div>
    </div>
    <!--    <div class="flex justify-center cursor-pointer" :style="`color:${color}`" @click="openQuestion">-->
    <!--      {{ openCheck1 ? 'Thu gọn' : 'Xem thêm' }}-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts" setup>
import Tinymce from "@/components/tinymce.vue";
// import CkContent from "@/components/ck-content.vue";
import BtnBase from "@/components/elements/BtnBase.vue"
// import ListQuestion from "@/components/question/ListQuestion.vue";
// import DataTable from "@/components/elements/DataTable.vue";
import BtnInTable from "@/components/elements/BtnInTable.vue";
import { defineProps, defineEmits, ref, toRef, onMounted, watch, inject, computed } from "vue";
import { useStore } from "vuex";
import { UPLOAD_AUDIO } from "@/store/upload";
import { messageError } from "@/common/message.service";
import { sanitizeTitle } from "@/mixin/sanitizeTitle";

const emit = defineEmits(['addComponent', 'clearQuestion', 'addAudio', 'copyQuestion'])
const props = defineProps({
  color: {
    type: String,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: '#7E7E7E',
  },
  question: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {},
  },
  index: {
    type: Number,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: 0,
  },
  index_question: {
    type: Number,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: 0,
  },
})
const store = useStore();
const Header = ref(
  [
    { width: '25%', text: "Chọn đáp án đúng", value: "is_correct" },
    { width: '25%', text: "Nội dung đáp án", value: "content" },
    { width: '35%', text: "Audio", value: "media" },
    { width: '15%', text: "Thao tác", value: "action" },
  ]
)
const head_matching = ref(
  [
    { width: '10%', text: "STT", value: "stt" },
    { width: '35%', text: "Nội dung", value: "content" },
    { width: '35%', text: "Audio", value: "media" },
    { width: '15%', text: "Thao tác", value: "action" },
  ]
)
const questions = toRef(props, "question");
const check = computed(() => store.getters.checkBoxQuestion)
const check1 = computed(() => store.getters.select_question)
const countClick = computed(() => store.getters.countClick)
const count = toRef(props, "index");
const open_block_answer = ref(false)
const answerOrder = ref(0)
const answer = ref()
const openCheck1 = ref(false);
const openCheck = ref(false);
const current_index = ref(0);
current_index.value = count.value + 1;

const open_block_answer_left = ref(false)
const answerOrder_left = ref(0)
const blockSeo = ref(false)

watch(check, (newVal) => {
  openCheck.value = newVal
});

watch(countClick, (newVal) => {
  openCheck.value = questions.value.status
})

watch(questions.value, (newVal) => {
  if (newVal && newVal.content) {
    let slug = newVal.content.trim();
    const maxLength = 100;
    if(slug.length > maxLength){
      const lastSpaceIndex = slug.lastIndexOf(" ", maxLength);
      slug = slug.substring(0, lastSpaceIndex);
    }
    slug = removeTagHtml(slug);
    slug = sanitizeTitle(slug);
    newVal.slug = slug;
  }
})
const removeTagHtml = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.body.innerText ?? "";
}
const open_block_answer_right = ref(false)

const answerOrder_right = ref(0)

const group_a: any = ref([])
const group_b: any = ref([])

onMounted(() => {
  resetAnswer();
  groupMatching(questions.value.answers)
})
//question
const replace_image_header = computed(() => {
  let replace_header: any = {};
  replace_header = Object.assign({}, questions.value);
  replace_header.content = regexImageUrl(replace_header.content)
  return replace_header;
});

const regexImageUrl = (content: string) => {
  if (!content) {
    return null;
  }
  content = content.replace('<img', '');
  content = content.replace('<table', '');
  return content;
}
const openQuestion = () => {
  openCheck.value = !openCheck.value
}

function openBlockSeo() {
  blockSeo.value = !blockSeo.value;
}

function getFileTemporary(e, question_id) {
  let file = e.target.files[0];
  let formData = new FormData();
  formData.append('files[]', file)
  formData.append('type', 'exams')

  store.dispatch(`${UPLOAD_AUDIO}`, formData).then(data => {
    if (data.status) {
      addAudio(data.data.data[0], question_id)
    }
  })
}

function lastQuestion(data) {
  questions.value.questions = data
}

function addAudio(data, question_id) {
  let payload = {
    data: data,
    question_id: question_id
  }
  emit('addAudio', payload)
}

function chooseFileTemporary(question_id) {
  window.document.getElementById('file' + question_id).click()
}

function clearQuestion(question_id, index_question) {
  let object = {
    index: index_question,
    question_id: question_id
  }
  emit('clearQuestion', object)
}

function copyQuestion(question) {
  let random_id = Math.random();
  let object = JSON.parse(JSON.stringify(question));
  object.random_id = random_id
  delete object.id
  let newQuestion = [];
  let newAnswer = [];
  if (object && object.questions && object.questions.length > 0) {
    newQuestion = clearIdQuestion(object.questions)
  }
  if (object && object.answers && object.answers.length > 0) {
    newAnswer = clearIdAnswer(object.answers, object.random_id)
  }
  object.questions = newQuestion;
  object.answers = newAnswer;

  emit('copyQuestion', object)
  // questions.value.questions.push(object)
}

function clearIdQuestion(question) {
  let random_id = 0;
  let q: any = question.map((el) => {
    random_id = Math.random();
    el.random_id = random_id
    delete el.id
    delete el.seo.id
    if (el.questions && el.questions.length > 0) {
      // console.log(el.questions,34);
      clearIdQuestion(el.questions)
    } else {
      clearIdAnswer(el.answers, el.random_id)
    }
    return el
  })
  // console.log(question.questions,32)
  return q
}

function clearIdAnswer(answers, question_id) {
  let random_id = 0;
  let a: any = answers.map((el) => {
    random_id = Math.random();
    el.random_id = random_id
    el.question_id = question_id
    delete el.id
    return el
  })
  return a;
}

function addQuestion(question) {
  if (question.answers && question.answers.length > 0) {
    alert('Câu hỏi có đáp án ko được phép có câu hỏi con')
  } else {
    question.type_answer = null;
    let random_id = Math.random();
    let object = {
      random_id: random_id,
      content: '',
      media_id: null,
      page_break: false,
      questions: [],
      answers: [],
      type_answer: 1,
      slug: '',
      seo: {
        title: '',
        keyword: '',
        description: '',
      }
    }
    questions.value.questions.push(object)
  }
}

// answer

function openBlockAnswer(questions) {
  if (questions.questions && questions.questions.length > 0) {
    alert('Câu hỏi có câu hỏi con không được phép thêm đáp án.')
  } else if (questions.type_answer == null) {
    alert('Hãy chọn kiểu câu hỏi.')
  } else {
    // open_block_answer.value = true
    answerOrder.value = 0;
    let random_id = Math.random()
    resetAnswer(random_id);
    questions.answers.push({
      random_id: answer.value.random_id,
      content: answer.value.content,
      question_id: answer.value.question_id,
      media_id: answer.value.media_id,
      is_correct: answer.value.is_correct,
      media: answer.value.media
    })
    resetAnswer();
  }
}

function chooseFileAnswerTemporary(answer_id) {
  window.document.getElementById('file' + answer_id).click()
}

function updateAnswer(list_answer, type) {
  list_answer.map(el => {
    if ((el.random_id && el.random_id == answer.value.random_id) || (el.id && el.id == answer.value.id)) {
      el.content = answer.value.content;
      el.media_id = answer.value.media_id;
      el.media = answer.value.media;
      el.is_correct = answer.value.is_correct;
      el.question_id = answer.value.question_id;
    }
  })
  resetAnswer();
  if (type == 'normal') {
    open_block_answer.value = false;
  }
  if (type == 'left') {
    open_block_answer_left.value = false;
  }
  if (type == 'right') {
    open_block_answer_right.value = false;
  }
}

function cancleEdit() {
  resetAnswer();
}

function editAnswer(list_answer, item, order = 0) {
  let data = list_answer.filter(el => (item.id && el.id == item.id) || (el.random_id && el.random_id == item.random_id))[0]
  answerOrder.value = order + 1;
  answer.value.random_id = data.random_id ?? null;
  answer.value.id = data.id;
  answer.value.content = data.content;
  answer.value.media_id = data.media_id;
  answer.value.media = data.media;
  answer.value.is_correct = data.is_correct;
  answer.value.question_id = data.question_id;
  open_block_answer.value = true;
}

function clearAnswer(list_answer, answer) {
  const inx = list_answer.findIndex(v => (v.random_id && v.random_id === answer.random_id) || (v.id && v.id === answer.id))
  list_answer.splice(inx, 1)
}

/* eslint-disable-next-line */
function getFileAnswerTemporary(e, answer_id) {
  let file = e.target.files[0];
  let formData = new FormData();
  formData.append('files[]', file)
  formData.append('type', 'exams')

  store.dispatch(`${UPLOAD_AUDIO}`, formData).then(data => {
    if (data.status) {
      answer.value.media_id = data.data.data[0].media_id;
      answer.value.media = {
        id: data.data.data[0].media_id,
        path: data.data.data[0].path,
        url: data.data.data[0].url,
      }
    }
  })
}


function radio(e, answer, answers) {
  console.log(e, answer, answers)
  answers.forEach(el => {
    if ((el.random_id && el.random_id == answer.random_id) || (el.id && el.id == answer.id)) {
      el.is_correct = true
    } else {
      el.is_correct = false
    }
  })
}

function openBlockAnswerMatch(questions) {
  if (questions.questions && questions.questions.length > 0) {
    alert('Câu hỏi có câu hỏi con không được phép thêm đáp án.')
  } else if (questions.type_answer == null) {
    alert('Hãy chọn kiểu câu hỏi.')
  } else {
    answerOrder_left.value = 0;
    let random_id = Math.random()
    resetAnswer(random_id);
    questions.answers.push({
      random_id: answer.value.random_id,
      content: answer.value.content,
      question_id: answer.value.question_id,
      media_id: answer.value.media_id,
      media: answer.value.media,
      type: 1
    })
    resetAnswer();
  }
}

function openBlockAnswerMatchRight(questions) {
  if (questions.questions && questions.questions.length > 0) {
    alert('Câu hỏi có câu hỏi con không được phép thêm đáp án.')
  } else if (questions.type_answer == null) {
    alert('Hãy chọn kiểu câu hỏi.')
  } else {
    answerOrder_right.value = 0;
    let random_id = Math.random()
    resetAnswer(random_id);
    questions.answers.push({
      random_id: answer.value.random_id,
      content: answer.value.content,
      question_id: answer.value.question_id,
      media_id: answer.value.media_id,
      media: answer.value.media,
      type: 2
    })
    resetAnswer();
  }
}

function editAnswerLeft(list_answer, item, order = 0) {
  let data = list_answer.filter(el => (item.id && el.id == item.id) || (el.random_id && el.random_id == item.random_id))[0]
  answerOrder_left.value = order + 1;
  answer.value.random_id = data.random_id ?? null;
  answer.value.id = data.id;
  answer.value.content = data.content;
  answer.value.media_id = data.media_id;
  answer.value.media = data.media;
  answer.value.is_correct = data.is_correct;
  answer.value.question_id = data.question_id;
  open_block_answer_left.value = true;
}

function editAnswerRight(list_answer, item, order = 0) {
  let data = list_answer.filter(el => (item.id && el.id == item.id) || (el.random_id && el.random_id == item.random_id))[0]
  answerOrder_right.value = order + 1;
  answer.value.random_id = data.random_id ?? null;
  answer.value.id = data.id;
  answer.value.content = data.content;
  answer.value.media_id = data.media_id;
  answer.value.media = data.media;
  answer.value.is_correct = data.is_correct;
  answer.value.question_id = data.question_id;
  open_block_answer_right.value = true;
}

function groupMatching(answers) {
  group_a.value = []
  group_b.value = []
  answers.forEach((el: any) => {
    if (el.type == 1) {
      group_a.value.push(el)
    }
    if (el.type == 2) {
      group_b.value.push(el)
    }
  })
}

watch(questions.value, (newVal, oldVal) => {
  if (newVal) {
    // if(check1.value && check1.value == newVal.index) {
    //   openCheck.value = !openCheck.value
    // }
    // else {
    //   openCheck.value = false
    // }
    groupMatching(newVal.answers);
  }
})


function addSttAnswer(e, answer, answers) {
  let stt = e.target.value;
  let isDup = answers.filter(v => v.stt == stt)
  if (isDup && isDup.length > 0) {
    messageError('Không được để trùng index.')
  } else {
    answers.forEach(el => {
      if (el.id && el.id == answer.id) {
        if (stt <= 0) {
          stt = 0
        }
        el.stt = stt
      }
      if (!el.id && el.random_id == answer.random_id) {
        if (stt <= 0) {
          stt = 0
        }
        el.stt = stt
      }
    })
  }

}

const resetAnswer = (random_id: number | null = null) => {
  answer.value = {
    id: null,
    random_id: random_id,
    content: "",
    question_id: null,
    media_id: null,
    is_correct: false,
    media: {}
  }
}

</script>

<style scoped lang="scss">
.header-tittle-question {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  @apply absolute top-[-40px] text-white w-[100%] ml-[60px];
}

.header-index-question {
  @apply absolute top-[-40px] text-white w-[50px];
}

.block-btn {
  @apply flex;

  .text-question {
    @apply text-gray-101 text-[18px] font-semibold underline decoration-2 underline-offset-8
  }

  .btn {
    @apply flex;

    .btn-add {
      @apply py-2 text-white bg-blue-101 rounded-3xl px-6 text-[14px] first:mr-8 cursor-pointer
    }
  }

  .inherit {
    display: inherit;
  }
}
.upload-file {
        @apply border-dashed rounded-xl border-gray-103 border items-center flex px-5 py-1 text-[12px] text-gray-101;

        .drop-file {
          @apply text-[#4097A3] mx-1
        }
      }
@media screen and (min-width: 912px) {

  .create-question {
    @apply px-[24px] w-[100%];
  }

  .block-action {
    @apply flex justify-between mt-5;

    .block-upload {
      @apply flex items-center w-[100%];

      .upload-file {
        @apply mr-5;
      }
    }

    .block-page-break {
      @apply flex items-center;

      input {
        @apply w-[20px] h-[20px] accent-blue-101 cursor-pointer
      }

      label {
        @apply text-[18px] text-gray-101 ml-3
      }
    }
  }

  .block-answer {
    @apply my-4 border rounded-lg p-4
  }
  .add_answer{
    @apply flex items-center my-3 
  }
  .add__audio, .audio{
    @apply w-[80%]
   
  }
}

@media screen and (max-width: 911px) {
  .create-question {
    @apply px-[8px] w-[100%];
  }
  .add__audio, .audio{
    @apply w-[100%] mb-4
   
  }
  .upload-file, .add__audio{
    @apply w-[100%] mt-4 
  }
}</style>
